<template>
  <b-container fluid>
    <b-card class="justify-content-center">
      <div class="custom-form-flex pb-4">
        <b-form id="alter-form" class="alter-form" :key="formSubmited">
          <b-form-row class="ml-2 pr-2 pb-2 p2">
            <b-col class="text-size-mobile">
              Tipo de Declaração
            </b-col>
            <b-col class="text-size-mobile">
              <div class="label-right">
                <b-form-input
                  v-model.lazy.trim="form.declarationType"
                  :state="validateField('declarationType')"
                />
              </div>
            </b-col>
          </b-form-row>
          <b-form-row class="ml-2 pr-2 pb-2 p2">
            <b-col class="text-size-mobile">
              Data de Início
            </b-col>
            <b-col class="text-size-mobile">
              <date-picker
                datePickerId="startDate"
                :key="form.startDate"
                :dataValue="form.startDate"
                :state="validateFieldStartDate('startDate')"
                @datePickerChangedValue="onChangedStartDate"
                :required="true"
              />
            </b-col>
            <div
              class="invalid text-invalid-right"
              v-show="validateFieldStartDate('startDate') == false"
            >
              {{ startDateErrorMsg }}
            </div>
          </b-form-row>
          <b-form-row class="ml-2 pr-2 pb-2 p2">
            <b-col class="text-size-mobile">
              Data de Término
            </b-col>
            <b-col>
              <date-picker
                datePickerId="endDate"
                :key="form.endDate"
                :dataValue="form.endDate"
                :state="validateFieldEndDate('endDate', 'startDate')"
                @datePickerChangedValue="onChangedEndDate"
                :required="true"
              />
            </b-col>

            <div
              class="invalid text-invalid-right"
              v-show="validateFieldEndDate('endDate', 'startDate') == false"
            >
              {{ endDateErrorMsg }}
            </div>
          </b-form-row>
          <b-form-row class="ml-2 pr-2 pb-2 p2">
            <b-col class="text-size-mobile">
              Mensagem
            </b-col>
            <b-col>
              <b-form-textarea
                id="textarea"
                v-model="form.message"
                :state="validateField('message')"
                placeholder="Escreva uma descrição para sua solicitação…"
                size="lg"
              ></b-form-textarea>
            </b-col>
          </b-form-row>
        </b-form>
      </div>
    </b-card>
    <b-button
      :disabled="loading || this.$store.getters.isAdmin"
      type="button"
      class="m-3"
      variant="primary"
      @click="onSubmit"
    >
      Solicitar declaração
      <i class="fas fa-spinner fa-spin icon" v-show="this.loading"></i>
    </b-button>
    <success-modal
      ref="success-modal"
      description="Sua solicitação foi enviada."
      buttonText="Ok, entendi!"
      subdescription="Os dados inseridos serão analisados e cadastrados após a validação."
      :protocol="this.protocol"
    />
  </b-container>
</template>

<script>
import {
  requiredFieldsFilled,
  validateField,
  validateFieldStartDate,
  validateFieldEndDate,
} from "../../../../utils/validate/validate.js";
import { addDeclaration } from "../../../../services/declaration/declaration-service";
import ServiceRequestType from "../../../../static-data/ServiceRequestType";
import SuccessModal from "../../../../components/base-modals/SuccessModal.vue";
import DatePicker from "../../../../components/date-picker/DatePicker.vue";

const DefaultForm = {
  name: "Solicitação de Declaração",
  startDate: null,
  endDate: null,
  declarationType: null,
  message: null,
  endDateErrorMsg: "",
  startDateErrorMsg: "",
};

export default {
  name: "declaration-informations",
  components: { SuccessModal, DatePicker },
  data() {
    return {
      protocol: "",
      formSubmited: false,
      loading: false,
      form: { ...DefaultForm },
      endDateErrorMsg: "",
      startDateErrorMsg: "",
      validations: {},
      validateField,
      requiredFieldsFilled,
      validateFieldStartDate,
      validateFieldEndDate,
    };
  },
  methods: {
    async onSubmit() {
      this.formSubmited = true;
      this.loading = true;
      if (this.requiredFieldsFilled()) {
        this.protocol = await addDeclaration(
          this.form,
          ServiceRequestType.DOCTOR_DECLARATION_OTHERS
        );
        const modal = this.$refs["success-modal"].$children[0];
        (this.form = DefaultForm), modal.show();
        this.formSubmited = false;
      }
      this.loading = false;
    },
    onChangedStartDate(value) {
      this.form.startDate = value;
    },
    onChangedEndDate(value) {
      this.form.endDate = value;
    },
  },
};
</script>

<style lang="scss" scoped>
div.card {
  border-color: transparent;
  border-radius: 1rem;
}

.label-right {
  width: 100%;
  text-align: right;
  padding-right: 1em;
}

@media screen and (max-width: 800px) {
  .text-size-mobile {
    font-size: 1.1rem;
  }
  .alter-form div.form-row {
    padding: 0.5rem;
  }
}
.align-right-all-time {
  margin-right: 1.2rem;
}
.text-invalid-right {
  width: 100%;
  text-align: right;
}
</style>
